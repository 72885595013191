var app = {};
app.init = function() {

    app.heroSlider();
    app.tour();
    app.video();
    app.videopreview();
    app.dropdown();
    app.hamburger();
    app.galleryModal();
    app.alphabetFilter();
    app.addExternalLinkOption();

};

// Home Sliders
app.heroSlider = function() {
    if ($('.slider').length) {

        // For Hero Slider Only 
        var slideIndex = 0;
        showSlides(slideIndex);

        function plusSlides(n) {
            showSlides(slideIndex += n);
        }

        function autoAdvance() {
            plusSlides(1);
        }

        autoAdvance();
        var timerId = setInterval(autoAdvance, 9000);

        $('.hero .sliderPrev').click(function() {
            plusSlides(-1);
            clearInterval(timerId);
        });
        $('.hero .sliderNext').click(function() {
            plusSlides(1);
            clearInterval(timerId);
        });
        $('.pause-slideshow').on('click', function() {
            clearInterval(timerId);
        });

        function showSlides(n) {
            var i;
            var x = document.getElementsByClassName("heroSlides");
            if (n > x.length) { slideIndex = 1 }
            if (n < 1) { slideIndex = x.length };
            for (i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
            x[slideIndex - 1].style.display = "block";
        }
    };
};

//Tour Ajax Call
// 

app.tour = function() {
    const tourContainer = $('#tourDates');

    $.ajax({
        type: 'GET',
        url: 'https://tour.santana.com/api/liveevents/SantanaFanClub',
        success: function(result) {
            parseData(result);
        }
    });

    function parseData(responseData) {
        const events = responseData;
        let items = "";

        if (events.length === 0) $('#noDates').show();

        for (let i = 0; i < events.length; i++) {

            let event = events[i].LiveEvent;

            // Get the Date and put it in a <div>
            let date = formatDate(new Date(event.EventDate.replace(/-/g, '\/').replace(/T.+/, '')));
            let dateWrap = $("<div>");
            dateWrap.addClass('date');
            dateWrap.append(date);

            // Get the Venue and Location Details and put it in a <div>
            //venue
            let venue = event.Venue;
            let venueLink = $("<a>");
            venueLink.attr('href', event.VenueUrl);
            venueLink.attr('target', '_blank');
            venueLink.addClass('location');
            venueLink.append(venue);
            //city
            let city = event.City;
            let region = event.Region;
            let country = event.Country;
            let note = event.EventListNote;
            let cityWrap = $('<span class="city">');
            cityWrap.append(city, `, `);
            if (region) cityWrap.append(region, `, `);
            cityWrap.append(country);
            cityWrap.append('<br>', note);
            //wrap
            let location = $('<div>');
            location.addClass('venue');
            location.append(venueLink, cityWrap);

            //Get Facebook RSVP and put it in a link
            let fbText;
            let fbEvent;
            if (event.FacebookEventId) fbText = "<i class='fab fa-facebook-square'></i> RSVP", fbEvent = "https://www.facebook.com/events/" + event.FacebookEventId;
            let fbLink = $("<a role='button'>");
            fbLink.attr('href', fbEvent);
            fbLink.attr('target', '_blank');
            fbLink.append(fbText);

            // if facebook is included in this tour widget, add fbButton to listItem.append below
            let fbButton = $('<div class="RSVP">');
            if (event.FacebookEventId) fbButton.append(fbLink);

            // Get the Ticket Details and put it in a <button>
            let ticketText;
            let ticketEvent;

            if (event.ExternalTicketUrl) ticketText = "Buy Tickets", ticketEvent = "https://tour.santana.com/";

            let ticketLink = $("<a class'buy-ticket-link' role='button'>");

            ticketLink.attr('href', ticketEvent);
            ticketLink.attr('target', '_blank');
            ticketLink.append(ticketText);

            // if ticket purchase button is included in this tour widget, add ticketButton to listItem.append below
            let ticketButton = $('<div class="buyTicket">');
            if (event.ExternalTicketUrl) ticketButton.append(ticketLink);

            let listItem = $("<li>");
            listItem.addClass('tourDate');
            listItem.append(dateWrap, location, ticketButton);

            //NOTE listItem is an object so I accessed the property outerHTML to get the <li> html in string form
            let listItemHtml = listItem[0].outerHTML;
            items += listItemHtml;
        }
        addToPage(items);
    }

    function addToPage(items) {
        tourContainer.append(items);
    }

    function formatDate(date) {
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        let day = date.getDate();
        let dayString = ('0' + day).slice(-2);
        let monthIndex = date.getMonth();
        //let year = date.getFullYear();

        return '<span class="month">' + monthNames[monthIndex] + ' </span>' + '<span class="day">' + dayString + '</span>'; // + '<span class="year">' + year + '</span>';
    }
}

//Youtube Video Embed
app.video = function() {

    var div, n,
        v = document.getElementsByClassName("youtube-player-single");
    for (n = 0; n < v.length; n++) {
        div = document.createElement("button");
        div.setAttribute("data-id", v[n].dataset.id);
        div.setAttribute("data-title", v[n].dataset.title);
        div.setAttribute("title", v[n].dataset.title);
        div.setAttribute("class", "video-player-button");
        div.innerHTML = videoThumb(v[n].dataset.id);
        div.onclick = videoIframe;
        v[n].appendChild(div);
    };

    function videoThumb(id) {
        let imgAlt = v[n].dataset.title;
        var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}" width="480" height="360" loading="lazy">`,
            play = '<div class="play"></div>';
        return thumb.replace("ID", id) + play;
    };

    function videoIframe() {
        var iframe = document.createElement("iframe");
        var embed = "https://www.youtube.com/embed/ID?autoplay=1";
        iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
        iframe.setAttribute("title", this.dataset.title);
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "1");
        iframe.setAttribute("width", "882");
        iframe.setAttribute("height", "495");
        iframe.setAttribute("loading", "lazy");
        this.parentNode.replaceChild(iframe, this);
    };
};

// variation of video function for landing pages
app.videopreview = function() {

    var div, n,
        v = document.getElementsByClassName("youtube-player-preview");
    for (n = 0; n < v.length; n++) {
        div = document.createElement("div");
        div.setAttribute("data-id", v[n].dataset.id);
        div.setAttribute("data-title", v[n].dataset.title);
        div.innerHTML = videoThumb(v[n].dataset.id);
        v[n].appendChild(div);
    };

    function videoThumb(id) {
        let imgAlt = v[n].dataset.title;
        var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}" width="480" height="360" loading="lazy">`;
        return thumb.replace("ID", id);
    };
};

//Dropdowns
app.dropdown = function() {
    $('.dropdown').click(function() {
        if ($(this).hasClass('button-with-open-content')) {
            $(this).siblings('.dropdownContent').removeClass('dropdown-is-open');
            $(this).attr('aria-expanded', 'false');
            $(this).removeClass('button-with-open-content');

        } else {
            $(this).siblings('.dropdownContent').addClass('dropdown-is-open');
            $(this).attr('aria-expanded', 'true');
            $(this).addClass('button-with-open-content');
        }
    });

    $('.shows-archive-nav-button').on('click', function() {
        let isShowsNavOpen = $('.shows-archive-nav-showing');
        if (isShowsNavOpen.length > 0) {
            $('.shows-archive-nav').removeClass('shows-archive-nav-showing');
            $('.shows-archive-nav-button').attr('aria-expanded', 'false');
        } else {
            $('.shows-archive-nav').addClass('shows-archive-nav-showing');
            $('.shows-archive-nav-button').attr('aria-expanded', 'true');
        }
    });
};

//Mobile Hamburger Menu
app.hamburger = function() {
    $('.nav-icon-container').on("click", function() {
        $('.nav-icon').toggleClass('open');
        $("body").toggleClass('mobile-menu-open');

        let isNavOpen = $('.mobile-menu-open');

        if (isNavOpen.length > 0) {
            $('.nav-icon-container').attr('aria-expanded', 'true');
        } else {
            $('.nav-icon-container').attr('aria-expanded', 'false');
        }
    });

    // Tabbing through last menu item closes the modal
    $(".header-mobile-menu >ul li:last-child ul li:last-child a").focusout(function() {
        $(".nav-icon-container").attr("aria-expanded", "false");
        $("body").removeClass("mobile-menu-open");
        $('.nav-icon').toggleClass('open');
        $('.nav-icon-container').focus();
    })

    //add aria haspopup to all main nav submenu toggles, so that screen readers announce that a submenu exists
    $('.menu-item-has-children>a').attr('haspopup', 'true').addClass("has-submenu");

    //add aria label to main nav submenus
    $('.sub-menu').attr('aria-label', 'submenu');

    //ie and edge fallback for focus-within in desktop nav keyboard tabbing
    $('.main-nav .has-submenu').on('focus', function() {
        $(this).siblings('.sub-menu').addClass('submenu-showing');
    });

    $('.main-nav .has-submenu').on('blur', function() {
        $(this).siblings('.sub-menu').removeClass('submenu-showing');
    });
}

app.galleryModal = function() {

    var currentIndex = "";
    const imageModal = document.querySelector('.gallery-modal');
    const closeButton = document.querySelector(".gallery-modal-close");

    let previousActiveElement;

    if (imageModal) {
        $('.previewImage').on('click', function() {
            currentIndex = $(this).data('index');
            callFlick(currentIndex);
            previousActiveElement = document.activeElement;
            imageModal.classList.add('modal-showing');
            $('body').addClass('body-with-open-modal');
            $(imageModal).attr('aria-hidden', 'false');
            closeButton.focus();
            return previousActiveElement;
        });

        $('.previewImage').focus(function() {
            if ($('.gallery-modal').hasClass('modal-showing')) {
                closeImageModal();
            }
        });

        function callFlick(i) {
            $('.main-carousel').flickity({
                cellAlign: 'left',
                contain: true,
                wrapAround: true,
                adaptiveHeight: true,
                imagesLoaded: true,
                initialIndex: i
            });
        };

        closeButton.addEventListener('click', closeImageModal);

        $(document).keydown(function(event) {
            if (event.keyCode == 27) {
                closeImageModal();
            }
        });

        function closeImageModal() {
            imageModal.classList.remove('modal-showing');
            $('body').removeClass('body-with-open-modal');
            $(imageModal).attr('aria-hidden', 'true');
            previousActiveElement.focus();
            $('.main-carousel').flickity('destroy');
        };
    }

    // Move the carousel buttons for accessibilty
    $(".main-carousel .flickity-prev-next-button").insertBefore(".flickity-slider");

    $('.main-carousel-brands').flickity({
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        adaptiveHeight: true,
        imagesLoaded: true
    });

    $('.nav-carousel-brands').flickity({
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        adaptiveHeight: true,
        imagesLoaded: true,
        asNavFor: '.main-carousel-brands'
    });
}

app.alphabetFilter = function() {
    const carlosJammingLetters = $('.carlos-jamming > li');
    const jammingNav = $('.jamming-nav');
    let jammingItems = "";

    for (let i = 0; i < carlosJammingLetters.length; i++) {
        let jammingItemId = $(carlosJammingLetters[i]).attr('id');

        let jammingItemLetter = jammingItemId.slice(-1);
        jammingItems += '<li><a href="#' + jammingItemId + '">' + jammingItemLetter + '</a></li>';

    }

    jammingNav.html(jammingItems);

};

app.addExternalLinkOption = function() {
    var milagrosItem = document.getElementById('menu-item-60');
    if (milagrosItem) {
        var milagrosLink = milagrosItem.querySelector('a');
        milagrosLink.setAttribute('target', '_blank');
    }

    var fcItem = document.getElementById('menu-item-3967');
    if (fcItem) {
        var fcLink = fcItem.querySelector('a');
        fcLink.setAttribute('target', '_blank');
    }

    var fclubItem = document.getElementById('menu-item-68');
    if (fclubItem) {
        var fclubLink = fclubItem.querySelector('a');
        fclubLink.setAttribute('target', '_blank');
    }

    var msItem = document.getElementById('menu-item-69');
    if (msItem) {
        var msLink = msItem.querySelector('a');
        msLink.setAttribute('target', '_blank');
    }

    var fbItem = document.getElementById('menu-item-70');
    if (fbItem) {
        var fbLink = fbItem.querySelector('a');
        fbLink.setAttribute('target', '_blank');
    }

    var twItem = document.getElementById('menu-item-12353');
    if (twItem) {
        var twLink = twItem.querySelector('a');
        twLink.setAttribute('target', '_blank');
    }
};

$(document).ready(function() {
    app.init();
});